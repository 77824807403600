<template>
	<div>

		<h1 class="text-2xl font-bold mb-5">{{ company.name }} Admins</h1>

		<div class="border border-solid border-blue-200 rounded overflow-hidden">
			<datatable :data="admins" :columns="columns" :actions="actions" :loading="loading" :query="searchQuery" :dropdown="false" ref="table" />
		</div>

		<modal className="w-full md:w-2/5 xl:w-3/10 text-center pt-24" ref="deletedModal">

			<img :src="checkmark" class="mx-auto mb-8" height="90" alt="Check Mark">

			<div class="text-lg font-bold mb-4">
				Success!
			</div>
			<div class="text-xs mb-10">
				Admin deleted successfully
			</div>

			<button type="button" class="button bg-blue-500 hover:bg-blue-600 text-white" @click.prevent="$refs.deletedModal.close">
				Okay
			</button>
			
		</modal>

	</div>
</template>
<script>
	export default {
		data() {
			return {
				actions: [
					{
						text: 'delete',
						class: 'border border-red-500 text-red-500 rounded-sm px-4 py-2',
						action: this.delete,
						disabled: admin => admin.id === this.user.id
					}
				],
				admins: [],
				checkmark: require('@/assets/checkmark-base.svg'),
				columns: [
					{
						name: 'name',
						th: 'Name',
					},
					{
						name: 'roles',
						th: 'Role',
						render: (users, roles) => roles[0].name
					},
					{
						name: 'agent_code',
						th: 'Agent Code',
					},
					{
						name: 'email',
						th: 'Email',
					},
					{
						name: 'phone_no',
						th: 'Phone',
					},
				],
				loading: false,
				searchQuery: '',
			}
		},
		computed: {
			company() {
				return this.user.company;
			}
		},
		beforeMount() {
			this.getAdmins();
		},
		methods: {
			async delete(admin) {
				this.loading = true;
				await this.$post({
					url: `${this.$baseurl}/admins/delete/${admin.id}`,
					headers: this.headers,
					success: () => {
						this.$refs.deletedModal?.open();
						this.getAdmins();
					}
				});
				this.loading = false;
			},
			async getAdmins() {
				this.loading = true;
				await this.$get({
					url: `${this.$baseurl}/admins`,
					headers: this.headers,
					success: (response) => {
						this.admins = response.data.admins;
					}
				});
				this.loading = false;
			}
		}
	}
</script>